import React from "react"
import { graphql } from "gatsby"

import OnePoster from "../components/poster"

import Layout from "../components/layout"
import SEO from "../components/seo"

const Plakat = ({ data }) => {
  const poster = data.plakatyYaml
  const plakatImg = poster.pictures[2].childImageSharp.original
  function posterClass() {
    if (plakatImg.height > plakatImg.width) {
      return "pionowy"
    } else if (plakatImg.height === plakatImg.width) {
      return "full"
    } else {
      return "poziomy"
    }
  }
  return (
    <Layout>
      <SEO
        title={poster.name}
        description={poster.desc[0]}
        image={poster.pictures[2].childImageSharp.original.src}
      />

      <div>
        <OnePoster
          name={poster.name}
          year={poster.year}
          desc={poster.desc}
          plakatDrukInfo={poster.drukInfo}
          plakatFormat={poster.format}
          imgSrc={poster.pictures}
          posterClass={posterClass}
        />
      </div>
    </Layout>
  )
}
export const query = graphql`
  query($slug: String!) {
    plakatyYaml(fields: { slug: { eq: $slug } }) {
      name
      category
      year
      desc
      drukInfo
      format
      pictures {
        childImageSharp {
          original {
            src
            width
            height
          }
        }
      }
    }
  }
`

export default Plakat

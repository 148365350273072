import React from "react"
import CartContext from "../../context/cart-context"
let paragrafNum = 0
let formatNum = 0

const PopupBadge = () => {
  return (
    <div className="mobileOnly successBadge">
      <p>Dodano do koszyka</p>
    </div>
  )
}

class onePoster extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      currentImg: this.props.imgSrc[2].childImageSharp.original.src,
      isLoading: false,
    }
  }
  handleImgChange(newSrc) {
    this.setState({
      currentImg: newSrc,
    })
  }
  handleOnTouchStart(cart, format) {
    cart.addToCart({
      name: this.props.name,
      format: format.split(";")[0],
      cena: parseInt(format.split(";")[2]),
      imgSrc: this.props.imgSrc[1].childImageSharp.original.src,
    })
    this.setState(
      {
        isLoading: true,
      },
      () => {
        setTimeout(
          function() {
            this.setState({
              isLoading: false,
            })
          }.bind(this),
          1000
        )
      }
    )
  }

  render() {
    return (
      <CartContext.Consumer>
        {cart => (
          <div className="plakatContent">
            {this.state.isLoading ? <PopupBadge /> : ""}
            <div className="plakatImg800">
              <img
                className={this.props.posterClass()}
                alt={this.props.name}
                src={this.state.currentImg}
              />
            </div>
            <div className="posterOtherMockups mobileOnly">
              {this.props.imgSrc.slice(2).map(item => {
                return (
                  <img
                    onClick={this.handleImgChange.bind(
                      this,
                      item.childImageSharp.original.src
                    )}
                    alt={this.props.name}
                    src={item.childImageSharp.original.src}
                  />
                )
              })}
            </div>
            <div className="plakatDesc">
              <h1>
                {this.props.name}, {this.props.year}
              </h1>
              {this.props.desc.map(paragraf => {
                let paragrafKey = "par" + paragrafNum
                paragrafNum++
                return <p key={paragrafKey}>{paragraf}</p>
              })}

              <p className="plakatDrukInfo">{this.props.plakatDrukInfo}</p>
              <div className="availFormatsCombine">
                {this.props.plakatFormat.map(format => {
                  let formatKey = "format" + formatNum
                  let buttonKey = "button" + formatNum
                  formatNum++
                  return (
                    <div key={formatKey} className="availFormats">
                      <p>
                        <strong>
                          Format {format.split(";")[0]}
                          cm ({format.split(";")[1]}){" "}
                        </strong>{" "}
                        - {format.split(";")[2]}PLN
                      </p>
                      <button
                        key={buttonKey}
                        className="add-to-cart"
                        // onTouchStart={this.handleOnTouchStart.bind(
                        //   this,
                        //   cart,
                        //   format
                        // )}
                        onClick={this.handleOnTouchStart.bind(
                          this,
                          cart,
                          format
                        )}
                        // onClick={() => {
                        //   console.log("dupa")
                        //   cart.addToCart.bind(this, {
                        //     name: this.props.name,
                        //     format: format.split(";")[0],
                        //     cena: parseInt(format.split(";")[2]),
                        //     imgSrc: this.props.imgSrc[1].childImageSharp
                        //       .original.src,
                        //   })
                        // }}
                      >
                        Kup
                      </button>
                    </div>
                  )
                })}
              </div>
              <div className="posterOtherMockups desktopOnly">
                {this.props.imgSrc.slice(2).map(item => {
                  return (
                    <img
                      onClick={this.handleImgChange.bind(
                        this,
                        item.childImageSharp.original.src
                      )}
                      alt={this.props.name}
                      src={item.childImageSharp.original.src}
                    />
                  )
                })}
              </div>
            </div>
          </div>
        )}
      </CartContext.Consumer>
    )
  }
}
export default onePoster
